export function parseQueryParams(query: any) {
    return Object.fromEntries(
        query
            .substring(1)
            .split("&")
            .map((i: any) => {
                const [k, ...v] = i.split("=");
                return [k, v.join("=")];
            }),
    );
}