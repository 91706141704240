import EventEmitter from "events";

// USAGE:
//
// EventBus.<channel>.emit("event-type", ...args);
// EventBus.<channel>.addListener("event-type", (...args) => {...});
// EventBus.<channel>.removeListener("event-type", (...args) => {...});
//
// EXAMPLE:
//
// EventBus.session.emit("update", { "key": "value" });
//
// useEffect(() => {
//   const listener = (entries) => {...};
//   EventBus.session.addListener("update", listener);
//   return () => EventBus.session.removeListener("update", listener);
// }, []);
//
// eslint-disable-next-line import/no-anonymous-default-export
export default new Proxy(
    {
        session: {
            emit(name?: string, value?:any){},
            addListener(name?: string, value?:any){},
            removeListener(name?: string, value?:any){}
        },
        error: {
            addListener(name?: string, value?:any){},
        }
    },
    {
                isExtensible: (_) => false,
                // enumerate: (target) => Reflect.enumerate(target),
                has: (target, key) => Reflect.has(target, key),
                get: (target: any, key) => {
                    if (!(key in target)) target[key] = new EventEmitter();
                    return target[key];
                },
                set: (target, key, _) => false,
            },
    );
