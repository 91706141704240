import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";
import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import * as Analytics from "../../util/Analytics";
import EventBus from "../../util/EventBus";
import {useHandler} from "../../util/useHandler";
import CCButton from "../Common/CCButton";
import useAppContext from "../../util/AppContext";

export function SystemError() {

    const [visible, setVisible] = useState(false);

    const navigate = useNavigate();
    const appContext = useAppContext();

    const updateListener = useHandler(() => {
        Analytics.record("System Error Viewed", {});
        setVisible(true);
    }, []);

    useEffect(() => {
        EventBus.error.addListener("show-system-error", updateListener);
        return () => EventBus.session.removeListener("update", updateListener);
    }, [updateListener]);

    return visible ? (
        <Dialog open={true} scroll="body">
            <DialogTitle>System Error</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    An unexpected system error has occurred. To continue, please reload the page or logout.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                {appContext?.sessionContext.valid() ? (
                    <CCButton
                        color="secondary"
                        onClick={async () => {
                            Analytics.record("System Error Closing", {
                                Reason: "Logout",
                            });
                            navigate("/logout");
                        }}
                    >
                        Logout
                    </CCButton>
                ) : null}
                <CCButton
                    onClick={async () => {
                        Analytics.record("System Error Closing", {
                            Reason: "Reload",
                        });
                        window.location.reload();
                    }}
                >
                    Reload
                </CCButton>
            </DialogActions>
        </Dialog>
    ) : null;
}
