import React, {createContext, useContext} from "react";
import {SessionContext} from "./SessionContext";

type AppState = {
    sessionContext: SessionContext
}



export const AppContext = createContext<AppState>({sessionContext :new SessionContext({})});

export type AppContextProviderProps = {
    value: AppState;
    children: React.ReactNode;
}
export function AppContextProvider(props: AppContextProviderProps) {
    const ctx: AppState = {
        sessionContext: props.value.sessionContext
    }
    return <AppContext.Provider value={ctx}>{props.children}</AppContext.Provider>;
}

export default function useAppContext() {
    return useContext(AppContext);
}