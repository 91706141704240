import { createTheme, responsiveFontSizes, Theme } from '@mui/material';
import { Blue3, Blue4, Grey1b } from './styles';
// import type {} from '@mui/lab/themeAugmentation';
import React from "react";

declare module '@mui/material' {
  interface TypographyVariants {
    Sample: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    Sample?: React.CSSProperties;
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    Sample: true;
  }
}



    const customTheme: Theme = createTheme({
        spacing: 4,
        typography: {
            fontFamily: [
                "Roboto",
                "Helvetica Neue",
                "Arial",
                "sans-serif",
            ].join(","),
            fontSize: 16,
            fontWeightBold: 600,
            h1: {
                fontSize: "22px",
                fontWeight: 600,
            },
            h2: {
                fontSize: "18px",
                fontWeight: 600,
            },
            h3: {
                fontSize: "16px",
                fontWeight: 600,
            },
            h4: {
                fontSize: "14px"
            },
            h5: {
                fontSize: "14px"
            },
            h6: {
                fontSize: "14px",
                fontWeight: 400,
            },
            body1: {
                fontSize: 16,
            },
            body2: {
                fontSize: 14,
            },
            button: {
                textTransform: "none",
            },
            subtitle1: {
                fontSize: "22px",
                fontWeight: 600,
            },
            subtitle2: {
                fontSize: "18px",
                fontWeight: 600,
            }
        },
        palette: {
            mode: "light",
            primary: {
                main: Blue4,
            },
            secondary: {
                main: Blue3,
            },
            background: {
                default: Grey1b,
            },
        },
        components: {
            MuiButtonBase: {
                defaultProps: {
                    disableRipple: true,
                },
            },
            MuiButton: {
            },
            MuiLink: {
                defaultProps: {
                    underline: "hover", // mui5 uses 'always'
                },
            },
            MuiTextField: {
                defaultProps: {
                    variant: "outlined", // mui5 uses 'outlined'
                },
            },
            MuiDialogTitle: {
                styleOverrides: {
                    root: {
                        padding: "20px 8px 10px 16px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        fontSize: "1.125rem",
                        fontWeight: 600,
                    },
                },
            },
            MuiDialogContent: {
                styleOverrides: {
                    root: ({theme}) => ({
                        [theme.breakpoints.up("sm")]: {
                            minWidth: 410,
                        },
                        padding: "8px 16px",
                        "& .MuiGrid-root": {
                            paddingTop: "8px",
                        },
                        "& form": {
                            width: "100%",
                        },
                    }),
                },
            },
            MuiDialog: {
                styleOverrides: {
                    paper: ({theme}) => ({
                        [theme.breakpoints.up("sm")]: {
                            marginLeft: 60,
                            marginRight: 60,
                            minWidth: 600,
                        },
                    }),
                },
            },
            MuiInputLabel: {
                styleOverrides: {
                    shrink: {
                        backgroundColor: "#FFFFFF",
                        paddingLeft: 4,
                        paddingRight: 4,
                    },
                },
            },
            MuiInputBase: {
                styleOverrides: {
                    root: {
                        "& legend": {
                            maxWidth: 0,
                        },
                    },
                },
            },
            MuiFormGroup: {
                styleOverrides: {
                    root: {
                        marginTop: 12,
                    },
                },
            },
            MuiRadio: {
                styleOverrides: {
                    root: {
                        padding: 4,
                        paddingLeft: 10,
                    },
                },
            },
            MuiCheckbox: {
                styleOverrides: {
                    root: {
                        padding: 0,
                        paddingLeft: 10,
                    },
                },
            },
            MuiMenuItem: {
                styleOverrides: {
                    root: {
                        minHeight: "36px !important",
                    },
                },
            },
            MuiTypography: {
                variants: [
                    {
                        props: { variant : 'Sample'},
                        style: {
                            fontSize: 24,
                            fontWeight: 400,
                            color: Blue3,
                        }
                    }
                ]
            },
        }
    });

export const theme = responsiveFontSizes(customTheme)